import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { Button, SafeAreaView, StyleSheet, Text, View, Image } from 'react-native';

import { useFonts } from 'expo-font';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import RootNavigationTypes from './src/navigation/rootNavigationTypes';
import HomeScreen from './src/screens/homeScreen';
import GameScreen from './src/screens/gameScreen';


const Stack = createNativeStackNavigator<RootNavigationTypes>();


export default function App() {
  const [loaded] = useFonts({
    Nunito: require('./assets/fonts/Nunito-ExtraBold.ttf'),
  });

  if (!loaded) {
    return <Text>Loading</Text>
  }
  return ( 
  <NavigationContainer>
    <Stack.Navigator >
      <Stack.Screen name="Home" component={HomeScreen} options={{headerShown: false}}/>
      <Stack.Screen name="Game" component={GameScreen} options={{headerShown: false}} />
    </Stack.Navigator>
  </NavigationContainer>
  );
}
