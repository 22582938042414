import React from 'react'
import { View, Text, StyleSheet, Image } from 'react-native'
import GuessChar from './types/guessChar'



type GameTileProps = {
  char?: GuessChar | null
  previewChar?: string
}
const tile = require('./../assets/tiles/Tile.png');
const tile0 = require('./../assets/tiles/Tile0.png');
const tile1 = require('./../assets/tiles/Tile1.png');
const tile2 = require('./../assets/tiles/Tile2.png');
const tile3 = require('./../assets/tiles/Tile3.png');

export default function GameTile(props: GameTileProps) {
  const { char, previewChar } = props;

  if (!char) {
    return (
      <View style={[tileStyles.base]}>
        {
          previewChar &&
          <Text style={textStyles.base}>{previewChar.toLocaleUpperCase("tr")}</Text>
        }
        <Image style={tileStyles.image} source={tile} />
      </View>
    )
  }

  return (
    <View style={[tileStyles.base, char.isCorrectPosition ? tileStyles.correct : (char.isInWord ? tileStyles.correctPos : tileStyles.empty)]}>
      <Text style={textStyles.base}>{char.charter.toLocaleUpperCase("tr")}</Text>
      {
        char.isCorrectPosition ? 
        <Image style={tileStyles.image} source={tile1} /> : (char.isInWord ? 
        <Image style={tileStyles.image} source={tile2} /> : 
        <Image style={tileStyles.image} source={tile0} />)
      }
      
    </View>
  )
}

const textStyles = StyleSheet.create({
  base: {
    fontSize: 32,
    color: 'white',
    fontFamily: 'Nunito',
    fontWeight: 'normal'
  }
});


const tileStyles = StyleSheet.create({
  base: {
    width: 50,
    height: 50,
    margin: 4,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',


    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,

    elevation: 10,

    borderRadius: 8
  },
  empty: {
    // backgroundColor: 'red',
  },
  correct: {
    // backgroundColor: 'green'
  },
  correctPos: {
    // backgroundColor: '#aaaa00'
  },
  image: {
    position: 'absolute',
    zIndex: -1,
    width: 50,
    height: 50,
  }
});
