import { StyleSheet, Text, View, Image, Dimensions, Share, ImageBackground, TouchableOpacity } from 'react-native'
import React from 'react'
import PopupTypes from './types/popupTypes';
import * as Sharing from 'expo-sharing';

let cardHeight = 200;

type Props = {

    onRestart: () => void,
    popupType: PopupTypes,
    time?: string | null,
    wordToGuess?: string | null,
}
const Popup = (props: Props) => {
    const { onRestart, popupType, time, wordToGuess } = props;


    const share = async () => {
        if (wordToGuess) {
            // Sharing.shareAsync("https://google.com", {}).then((o) => {
            //     console.log("Shared", o);
            // }).catch((e) => {
            //     console.error("Error", e);
            // })

            try {
                const result = await Share.share({
                  message: 'React Native | A framework for building native apps using React',
                });
                if (result.action === Share.sharedAction) {
                  if (result.activityType) {
                    // shared with activity type of result.activityType
                  } else {
                    // shared
                  }
                } else if (result.action === Share.dismissedAction) {
                  // dismissed
                }
              } catch (error) {
                console.error(error);
              }
        }
    }

    switch (popupType) {
        case PopupTypes.Win:
            return (
                <View style={styles.popupCard}>
                    <View style={{ width: "100%", height: cardHeight }}>
                        <ImageBackground style={styles.imageBackground} source={require("../assets/popup/popupCard.png")} resizeMode={'contain'}>
                            <Text style={styles.title}>KAZANDIN</Text>
                            <View style={styles.body}>
                                <Text style={styles.label}>{wordToGuess?.toLocaleUpperCase('tr') ?? "-"}</Text>
                                <Text style={styles.label}>Süre: {time ?? "00:00"}</Text>
                            </View>
                        </ImageBackground>
                        <View style={styles.buttons}>
                            <TouchableOpacity activeOpacity={0.7} style={styles.button} onPress={() => onRestart()}>
                                <Image style={{ height: 57, width: 82 }} source={require("../assets/popup/restartButton.png")} />
                            </TouchableOpacity>
                            <TouchableOpacity activeOpacity={0.7} style={styles.button} onPress={share}>
                                <Image style={{ height: 57, width: 82 }} source={require("../assets/popup/shareButton.png")} />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            )
        case PopupTypes.Lose:
            return ( // Tasarımı Değişecek
                <View style={styles.popupCard}>
                    <View style={{ width: "100%", height: cardHeight }}>
                        <ImageBackground style={styles.imageBackground} source={require("../assets/popup/popupCard.png")} resizeMode={'contain'}>
                            <Text style={styles.title}>KAYBETTİN</Text>
                            <View style={styles.body}>
                                <Text style={styles.label}>DOĞRU KELİME</Text>
                                <Text style={styles.label}>{wordToGuess?.toLocaleUpperCase('tr') ?? "-"}</Text>
                            </View>
                        </ImageBackground>
                        <View style={styles.buttons}>
                            <TouchableOpacity activeOpacity={0.7} style={styles.button} onPress={() => onRestart()}>
                                <Image style={{ height: 57, width: 82 }} source={require("../assets/popup/restartButton.png")} />
                            </TouchableOpacity>
                            <TouchableOpacity activeOpacity={0.7} style={styles.button} onPress={share}>
                                <Image style={{ height: 57, width: 82 }} source={require("../assets/popup/shareButton.png")} />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            )
    }


}

export default Popup

const styles = StyleSheet.create({

    popupCard: {
        zIndex: 99,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },

    body: {
        margin: 20,
    },

    label: {
        fontSize: 20,
        textAlign: 'center',
        margin: 5,
        color: '#fff',
        fontWeight: '800',
        fontFamily: 'Nunito',
    },

    button: {
        marginHorizontal: 12
    },

    buttons: {
        position: 'absolute',
        top: cardHeight - 20,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },

    title: {
        fontSize: 20,
        textAlign: 'center',
        margin: 12,
        color: '#fff',
        fontWeight: '800',
        fontFamily: 'Nunito',
    },


    imageBackground: {
        flex: 1,
    },

})