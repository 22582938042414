import React from 'react'
import { Alert, KeyboardAvoidingView, Platform, SafeAreaView, ScrollView, Text, TextInput, View } from 'react-native'
import GameRow from './gameRow';
import GameTile from './gameTile';
import Keyboard from './keyboard';
import Guess from './types/guess';
import Keycaps from './types/keycaps';
type GameProps = {
  wordCount: number
  maxTrys?: number,
  wordToGuess: string,
  isValidWord: (word: string) => boolean,
  onWin: () => void,
  onLose: (correctWord: string) => void,
  setHintRegex: (regex: RegExp[], keycaps: Keycaps) => void,
  setWord?: string,
  setKeysHint?: Keycaps
}

export default function Game(props: GameProps) {
  const { wordCount, maxTrys, wordToGuess, isValidWord, onWin, onLose, setHintRegex, setWord, setKeysHint } = props;

  const [wordInput, setWordInput] = React.useState('');
  const [gameState, setGameState] = React.useState<(Guess)[]>([]);
  const [isWinning, setIsWinning] = React.useState(false);
  const [keycaps, setKeyCaps] = React.useState<Keycaps>({
    q: 'incorrect',
    w: 'incorrect',
    x: 'incorrect',
  });


  React.useEffect(() => {
    if (setWord) {
      setWordInput(setWord);
    }
  }, [setWord]);

  React.useEffect(() => {
    if (setKeysHint) {
      setKeyCaps({
        ...keycaps,
        ...setKeysHint
      });
    }
  }, [setKeysHint]);

  React.useEffect(() => {
    setIsWinning(false);
    setGameState([]);
    setKeyCaps({
      q: 'incorrect',
      w: 'incorrect',
      x: 'incorrect',});
  }, [wordToGuess, wordCount]);




  const action = (): void => {
    var word2 = wordInput.replaceAll("İ", "i").replaceAll("I", "ı").replaceAll("Ş", "ş").replaceAll("Ç", "ç").replaceAll("Ö", "ö").replaceAll("Ü", "ü");

    const word = word2.toLocaleLowerCase();

    if (word.length !== wordCount) {
      setWordInput('');
      alert(`Please enter a ${wordCount} letter word ${word.length} ${word}`);
      return;
    }

     if (!isValidWord(word)) {
       setWordInput('');
       if(Platform.OS === 'web') {
         alert('Kelime Sözlükte Bulunamadı. ');
       } else {
         Alert.alert('Geçersiz Kelime', 'Kelime Sözlükte Bulunamadı');
       }
  
       return;
     }

    const guess: Guess = { characters: [] };

    const correctWord = wordToGuess.split('');

    word.split('').forEach((char, index) => {
      guess.characters[index] = {
        charter: char,
        isInWord: false,
        isCorrectPosition: correctWord[index] === char,
      };
    });

    var usedCharacters: { [key: string]: number } = {};

    correctWord.forEach(char => {
      usedCharacters[char] = usedCharacters[char] ? usedCharacters[char] + 1 : 1;
    });

    word.split('').forEach((char, index) => {
      if (usedCharacters[char] > 0 && guess.characters[index].isCorrectPosition) {
        usedCharacters[char]--;
        guess.characters[index].isInWord = true;
      }
    });

    word.split('').forEach((char, index) => {
      if (usedCharacters[char] > 0) {
        usedCharacters[char]--;
        guess.characters[index].isInWord = true;
      }
    });
    // scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
    setWordInput('');
    setGameState([...gameState, guess]);

    

    const oldKeycaps = keycaps;
    guess.characters.forEach(char => {

      const keycap = oldKeycaps[char.charter];

      if (keycap === 'correctPos') {
        return;
      }

      if (char.isCorrectPosition) {
        oldKeycaps[char.charter] = 'correctPos';
        return;

      }

      if (keycap === 'correct') {
        return;
      }

      if (char.isInWord) {
        oldKeycaps[char.charter] = 'correct';
        return;
      }

      oldKeycaps[char.charter] = 'incorrect';


    });

    setKeyCaps(oldKeycaps);
    if (guess.characters.reduce((partialSum, a) => partialSum + (a.isCorrectPosition ? 1 : 0), 0) === wordCount) {
      setIsWinning(true);
      onWin();
    } else if (maxTrys && gameState.length === maxTrys) {
      setIsWinning(true);
      onLose(wordToGuess);
    }
    
  };

  React.useEffect(( ) => {
    scrollViewRef.current?.scrollTo({ x: 0, y: (gameState.length) * 58, animated: true });
  }, [gameState]);

  const scrollViewRef = React.useRef<ScrollView>(null);

  const inputRef = React.useRef<typeof GameRow>(null);

  React.useEffect(() => {
    wordInput.length === wordCount && action();
  }, [wordInput]);

  const generateRegexForGuesses = () => {
    const alphabet = 'abcçdefgğhıijklmnoöprsştuüvyz'.split('').filter(c => keycaps[c] !== 'incorrect').join('');

    var possibleCharactersWithGuesses: string[] = [];

    for (let i = 0; i < wordCount; i++) {
      possibleCharactersWithGuesses.push(alphabet);
    }

    var mustChars: {[key: string]: number} = {};
    var foundPos: {[key: string]: boolean} = {};

    gameState.map(guess => {
      guess.characters.forEach((char, index) => {
        if (char.isCorrectPosition) {
          foundPos[index] = true;
          possibleCharactersWithGuesses[index] = char.charter;
        } else if (char.isInWord) {
          foundPos[index] = false;
          possibleCharactersWithGuesses[index] = possibleCharactersWithGuesses[index].split('').filter(c => c !== char.charter).join(''); 
          mustChars[char.charter] = mustChars[char.charter] ? mustChars[char.charter] + 1 : 1;
        }
      });
    });

    var charPositions: number[] = Array.from(Array(wordCount).keys());

    // loop over foundPos
    
    for (let i = 0; i < wordCount; i++) {
      if (foundPos[i]) {
        charPositions = charPositions.filter(c => c !== i);
        continue;
      }
    }


    // get random element from charPositions

    var randomCharPosition = charPositions[Math.floor(Math.random() * charPositions.length)];

    const correctWord = wordToGuess.split('');
    // 50% of time
    if (Math.random() > 0.5) {
      possibleCharactersWithGuesses[randomCharPosition] = correctWord[randomCharPosition];
    }
    console.log(charPositions);

    // charPositions.forEach(pos => {
    //   if (foundPos[pos]) {
    //     return;
    //   }
    //   possibleCharactersWithGuesses[pos] = possibleCharactersWithGuesses[pos].split('').filter(c => mustChars[c] === undefined).join('');
    // });




    var regexes: RegExp[] = [];

    console.log(possibleCharactersWithGuesses);

    var out = "^";
    possibleCharactersWithGuesses.forEach(char => {
      out += `[${char}]`;
    });
    out += "$";




    return [new RegExp(out)];
  }


  const generateKeycaps = (): Keycaps => {


    const correctWord = wordToGuess.split('');

    const alphabet = 'abcçdefgğhıijklmnoöprsştuüvyz'.split('').filter(c => keycaps[c] !== 'incorrect').filter(c => !correctWord.includes(c)).join('');

    var possibleCharactersWithGuesses: string[] = [];

    for (let i = 0; i < wordCount; i++) {
      possibleCharactersWithGuesses.push(alphabet);
    }


    const alphabetArray = alphabet.split('');

    // get random 4 elements from alphabetArray
    console.log("keycaps", wordToGuess, alphabetArray);

    var randomChars = [];

    for (let i = 0; i < 5; i++) {
      randomChars.push(alphabetArray[Math.floor(Math.random() * alphabetArray.length)]);
    }

    // remove non unique elements from randomChars

    var uniqueChars: string[] = [];

    randomChars.forEach(char => {
      if (uniqueChars.includes(char)) {
        return;
      }
      uniqueChars.push(char);
    });


    console.log("uniqueChars", randomChars, uniqueChars);

    var caps: Keycaps = {};

    uniqueChars.forEach(char => {
      caps[char] = 'incorrect';
    });

    return caps
  }

  React.useEffect(() => {
    const regex = generateRegexForGuesses();
    const keycaps = generateKeycaps();
    
    setHintRegex(regex, keycaps);

  }, [gameState]);

  return (
    <View style={{ flex: 1, justifyContent: 'space-between' }} >

      <ScrollView  style={{ flex: 1}} ref={scrollViewRef} bounces scrollsToTop >
        <View style={{ alignItems: 'center' }}>

          {gameState.map((guess, index) => {
            return <GameRow key={index} guess={guess} wordCount={wordCount} />
          })}
          {
            !isWinning &&
            <GameRow  guess={null} input={wordInput} wordCount={wordCount} />
          }
          {
            maxTrys &&
            Array.from(Array(Math.max(0, maxTrys - gameState.length)).keys()).map((_, index) => {
              return <GameRow key={index} guess={null} wordCount={wordCount} />
            })
          }
        </View>
      </ScrollView>
      <Keyboard keycaps={keycaps} onPress={(k) => {

        scrollViewRef.current?.scrollTo({ x: 0, y: gameState.length * 58, animated: true });
        if (k === "enter") {
          // action();
        } else if (k === "delete") {
          setWordInput(wordInput => wordInput.substring(0, wordInput.length - 1));
        } else {
          // check word length
          if (wordInput.length !== wordCount) {
            setWordInput(wordInput => wordInput + k);
            // alert(`|${k}|`);
          }
        }

      }} />
    </View>
  )
}
