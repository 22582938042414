import { NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'
import { Button, FlatList, Image, Linking, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context';
import RootNavigationTypes from '../navigation/rootNavigationTypes'
import GameJSON from '../types/gameJson';

import Game from './../../game.json';

export default function HomeScreen(props: NativeStackScreenProps<RootNavigationTypes, 'Home'>) {
  const { navigation, route } = props;
  const homeScreenData = Game as GameJSON;




  return (<View style={styles.container}>
    <SafeAreaView style={{ flex: 1 }}>
      <FlatList style={{ flex: 1 }} data={homeScreenData.home}
        ListEmptyComponent={() => <Text>Empty</Text>}
        ListHeaderComponent={() => <View style={styles.header}>
          {/* <Button title='Ipucu 2'  onPress={() => giveHint2()} /> */}
          {/* <TouchableOpacity onPress={() => navigation.goBack()}>
        <Image source={require("../../assets/buttons/backButton.png")} style={{width: 28.5, height: 40}}  />
      </TouchableOpacity> */}
          <Image source={require("../../assets/kelimecik_logo_top.png")} style={{ width: 65, height: 70 }} />
          {/* <Button title='Ipucu' onPress={() => giveHint()} /> */}
        </View>}
        renderItem={({ item }) => ((
          <View style={styles.card} key={item.title}>
            <Text style={[styles.cardTitle,
            item.titleSize ? { fontSize: item.titleSize } : null,
            ]}>{item.title}</Text>
            {
              item.subtitle ?
              <Text style={styles.cardText}>{item.subtitle}</Text> : null
            }
            {/* <Button title='Devam' onPress={() => navigation.navigate('Game', { game: item.game })} /> */}

            {/* {item.rows.length === 1 ? (
              ((item) => (
                <TouchableOpacity onPress={() => navigation.navigate('Game', { settings: item.game })}>
                  <View style={styles.cardFullRow}>
                    <Image source={{ uri: item.image }} style={styles.cardImage} />
                    <Text style={styles.cardRowText}>{item.title}</Text>
                  </View>
                </TouchableOpacity>
              ))(item.rows[0])
            ) : ( */}
            <ScrollView horizontal>

              {
                item.rows.map((item, index) => {

                  const onClick = () => {

                    if (item.game) {
                      navigation.navigate('Game', { settings: item.game })
                    }

                    if (item.url) {
                      Linking.openURL(item.url)
                    }

                  }

                  return (
                    <TouchableOpacity key={index} onPress={onClick}>
                      <View style={[styles.cardRow, item.style === 'box1' ? styles.cardRowBox : null]}>

                        <Image source={{ uri: item.image }} style={styles.cardImage} />
                        {
                          item.titlePos ? (

                            <Text style={[styles.cardRowText,
                            item.titleSize ? { fontSize: item.titleSize } : null,
                            item.titlePos == 'bottom_left' ? styles.cardDetailsBottomLeft :
                              item.titlePos == 'bottom_right' ? styles.cardDetailsBottomRight :
                                item.titlePos == 'top_left' ? styles.cardDetailsTopLeft :
                                  item.titlePos == 'top_right' ? styles.cardDetailsTopRight : null]}>{item.title}</Text>
                          ) : null
                        }

                        {
                          item.badgePosition ? (

                            <View style={[styles.cardBadgeText,
                            item.badgeBgColor ? { backgroundColor: item.badgeBgColor } : null,
                            item.badgePosition == 'bottom_left' ? styles.cardDetailsBottomLeft :
                              item.badgePosition == 'bottom_right' ? styles.cardDetailsBottomRight :
                                item.badgePosition == 'top_left' ? styles.cardDetailsTopLeft :
                                  item.badgePosition == 'top_right' ? styles.cardDetailsTopRight : null]}>

                              <Text style={[styles.cardBadgeTextContent,
                              item.badgeColor ? { color: item.badgeColor } : null,
                              item.badgeSize ? { fontSize: item.badgeSize } : null,

                              ]}>{item.badge}</Text>
                            </View>
                          ) : null
                        }
                      </View>
                      {
                        item.help ? (
                          <TouchableOpacity style={[styles.cardDetails,
                          item.help == 'bottom_left' ? styles.cardDetailsBottomLeft :
                            item.help == 'bottom_right' ? styles.cardDetailsBottomRight :
                              item.help == 'top_left' ? styles.cardDetailsTopLeft :
                                item.help == 'top_right' ? styles.cardDetailsTopRight : null
                          ]} onPress={() => { }}>
                            <Image source={require("../../assets/buttons/cartDetailsButton.png")} style={{ width: 25, height: 25 }} />
                          </TouchableOpacity>
                        ) : null
                      }

                    </TouchableOpacity>
                  )
                })

              }

            </ScrollView>
            {/* )} */}


          </View>



        ))
        } />



    </SafeAreaView >
  </View >
  )
}

const styles = StyleSheet.create({
  card: {

  },
  cardTitle: {
    color: 'white',
    fontSize: 32,
    fontWeight: 'bold',
    marginLeft: 16,
    marginTop: 16,
    fontFamily: 'Nunito',
  },
  cardText: {

    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 16,
    marginBottom: 16,
    fontFamily: 'Nunito',

  },
  cardFullRow: {
    height: 190,
    padding: 16,
    marginLeft: 16,
    marginRight: 16,
  },
  cardRow: {
    padding: 16,
    marginLeft: 16,
    width: 315,
    height: 160
  },
  cardRowBox: {
    width: 140,

  },
  cardDetails: {
    position: 'absolute',
  },
  cardDetailsBottomLeft: {
    bottom: 16,
    left: 16,
  },
  cardDetailsBottomRight: {
    bottom: 16,
    right: 16,
  },
  cardDetailsTopLeft: {
    top: 16,
    left: 16,
  },
  cardDetailsTopRight: {
    top: 16,
    right: 16,
  },
  cardImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  cardRowText: {

    position: 'absolute',
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    zIndex: 10,
    fontFamily: 'Nunito'
  },
  cardBadgeTextContent: {

    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Nunito',

  },
  cardBadgeText: {
    backgroundColor: 'orange',
    padding: 6,
    borderRadius: 10,
    position: 'absolute',
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    zIndex: 10,
    fontFamily: 'Nunito'
  },
  container: {
    flex: 1,
    backgroundColor: '#1A1A1A',
    alignItems: 'stretch',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 16
  }
});
