import React from 'react'
import { Text, View } from 'react-native'
import GameTile from './gameTile';
import Guess from './types/guess'

type GameRowProps = {
  guess: Guess | null,
  wordCount: number,
  input?: string,
}


export default function GameRow(props: GameRowProps) {
  const { guess, wordCount, input } = props;
  
  return (
    <View style={{flexDirection: 'row'}}>
      {Array.from(Array(wordCount)).map((_, index) => {
        return <GameTile key={index} char={guess && guess.characters[index]} previewChar={ input ? input[index] : undefined } />
      })}
    </View>
  )
}
