import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, Platform } from 'react-native'
import { AntDesign, Ionicons } from '@expo/vector-icons';
import Keycaps from './types/keycaps';


type KeyboardProps = {
  onPress: (char: string) => void
  keycaps: Keycaps
}

export default function Keyboard(props: KeyboardProps) {

  const { onPress, keycaps } = props;

  const keys = [
    ["q", "w", "e", "r", "t", "y", "u", "ı", "o", "p", "ğ", "ü"],
    ["spacer", "a", "s", "d", "f", "g", "h", "j", "k", "l", "ş", "i", "spacer"],
    ["spacer", "spacer", "z", "x", "c", "v", "b", "n", "m", "ö", "ç", "delete"],
  ]

  const keyeventListener = (e: KeyboardEvent) => {
    if (e.key === 'Backspace') {
      onPress('delete');
      console.log('backspace');
      return;
    }
    else if (e.key === 'Enter') {
      onPress('enter');
      return;
    }
    const key = e.key.toLocaleLowerCase().trim();

    // if (keycaps[key] === "incorrect") {
    //   // onPress(key);
    //   alert("Incorrect");
    // }

    if (keys.some(row => row.some(k => k === key))) {


      onPress(key);
    }


  }

  React.useEffect(() => {

    if (Platform.OS === 'web') {
      document.addEventListener('keydown', keyeventListener);

    }

    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('keydown', keyeventListener);
      }
    }
  }, []);

  const keycapToStyle = (keycap: Keycaps['a']) => {
    // 'correct' | 'correctPos' | 'incorrect' | 'empty'
    if (keycap === 'correct') {
      return keycapStyles.correct;
    } else if (keycap === 'correctPos') {
      return keycapStyles.correctPos;
    } else if (keycap === 'incorrect') {
      return keycapStyles.incorrect;
    } else {
      return keycapStyles.empty;
    }
  }

  return (
    <View style={{
      marginBottom: 16,
      margin: 8
    }}>
      {keys.map((row, i) => (
        <View key={i} style={{ flexDirection: 'row' }}>
          {row.map((key, j) => {


            if (key === 'spacer') {
              return <View key={j} style={{ width: 10, minWidth: 8 }} />
            }

            if (key === 'enter') {
              return (<TouchableOpacity onPress={() => onPress("enter")} key={j} style={[styles.keys, { backgroundColor: '#AB72EB', minWidth: 16 }]}>
                <AntDesign name="check" style={styles.keyText} />
              </TouchableOpacity>)
            }


            if (key === 'delete') {
              return (<TouchableOpacity onPress={() => onPress("delete")} key={j} style={[styles.keys]}>
                <View style={[styles.keyContainer, { backgroundColor: '#9a9a9a' }]}>
                  <Ionicons name="backspace-outline" size={24} color={'white'} />
                </View>
                <View key={j} style={{ width: 10, minWidth: 8 }} />
              </TouchableOpacity>
              )
            }

            return (<TouchableOpacity onPress={() => onPress(key)} key={j} style={styles.keys}>
              <View style={[styles.keyContainer, keycapToStyle(keycaps[key])]}>
                <Text style={styles.keyText}>{key.toLocaleUpperCase("tr")}</Text>
              </View>
            </TouchableOpacity>)
          })}
        </View>
      ))}
    </View>
  )
}


const keycapStyles = StyleSheet.create({
  correct: {
    backgroundColor: '#FFA717'
  },
  correctPos: {
    backgroundColor: '#4AAD95'
  },
  incorrect: {
    backgroundColor: '#232323'
  },
  empty: {
    // backgroundColor: '#D3D3D3'
  }

});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  keys: {
    flex: 1,
    height: 55,
    alignContent: 'stretch',
    alignItems: 'stretch',

    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 5,
    // },
    // shadowOpacity: 0.34,
    // shadowRadius: 6.27,

    // elevation: 10,
  },

  keyContainer: {

    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: '#646464',
    borderRadius: 5,
    margin: 2.5,
    // marginBottom: 16,


    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,

    elevation: 10,
  },

  keyText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  keyRow: {
  }
});
