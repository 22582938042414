import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { Button, SafeAreaView, StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
import Game from '../game';
import Keyboard from '../keyboard';
import Popup from '../popup';
import Keycaps from '../types/keycaps';
import PopupTypes from '../types/popupTypes';

import WordList from '../words.json';
import { useFonts } from 'expo-font';
import RootNavigationTypes from '../navigation/rootNavigationTypes';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

var wordList: WordListType = WordList;

type WordListType = {
  lines: {
    [key: number]: string[]
  },
  lang: string,
  minLen: number,
  maxLen: number
}


export default function GameScreen(props: NativeStackScreenProps<RootNavigationTypes, 'Game'>) {
  const { navigation, route: {params: {settings: { fixedWord, maxTrys, wordCount, hintAllowFullWords, hintsEnabled }}} } = props;

  const [wordToGuess, setWordToGuess] = React.useState('');
  const [word, sendWord] = React.useState('');
  const [hintRegex, setHintRegex] = React.useState<RegExp[]>();
  const [hintKeycaps, setHintKeycaps] = React.useState<Keycaps>({});
  const [hintKeycapsSetter, setHintKeycapsSetter] = React.useState<Keycaps>({});

  const dictionary = wordList.lines[wordCount];

  const [showPopup, setShowPopup] = React.useState<PopupTypes | false>(false);

  const setWord = () => {
    if(fixedWord) {
      setWordToGuess(fixedWord);
    } else {
      setWordToGuess(dictionary[Math.floor(Math.random() * dictionary.length)]);
    }
  }

  

  React.useEffect(() => {
    setWord();
  }, [wordCount]);

  const giveHint = React.useCallback(() => {

    if (!hintRegex) {
      alert('Hint is not available');
      return;
    }

    console.log(hintRegex);

    const words = dictionary.filter(word => (hintRegex[0].test(word) && (!hintAllowFullWords ? word !== wordToGuess : true)));

    if (words.length === 0) {
      alert('No words found');
    } else {
      const randomWord = words[Math.floor(Math.random() * words.length)];
      sendWord(randomWord);
      // alert(`${words.length + 1} adet kelime şu anki kelimelere uygun, ${randomWord}`);
      // const word = words[Math.floor(Math.random() * words.length)];
      // setWordToGuess(word);
    }

  }, [wordToGuess, hintRegex]);


  const giveHint2 = React.useCallback(() => {

    if (!hintKeycaps) {
      alert('Hint is not available');
      return;
    }


    setHintKeycapsSetter(hintKeycaps);

  }, [wordToGuess, hintKeycaps]);
  

  return (
    <>
      <View style={styles.container}>
        <SafeAreaView style={{ flex: 1 }}>

          <View style={styles.header}>
            {/* <Button title='Ipucu 2'  onPress={() => giveHint2()} /> */}
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Image source={require("../../assets/buttons/backButton.png")} style={{width: 28.5, height: 40}}  />
            </TouchableOpacity>
            <Image source={require("../../assets/kelimecik_logo_top.png")} style={{ width: 65, height: 70 }} />
            {
              hintsEnabled ? <Button title='Ipucu' onPress={() => giveHint()} /> : <View />
            }
            
          </View>
          <Game wordCount={wordCount} maxTrys={maxTrys ? maxTrys - 1 : undefined} wordToGuess={wordToGuess} isValidWord={word => dictionary.includes(word) }
            setKeysHint={hintKeycapsSetter} setWord={word} setHintRegex={(r, k) => { setHintRegex(r); setHintKeycaps(k); }}
            onWin={() => setShowPopup(PopupTypes.Win)} onLose={() => setShowPopup(PopupTypes.Lose)}
          />

        </SafeAreaView>
        <StatusBar style="auto" />
      </View>

      {showPopup && (
        <Popup popupType={showPopup} wordToGuess={wordToGuess} onRestart={() => {
          if(fixedWord) {
            navigation.pop();
          } else {
            setWord();
            setShowPopup(false);
          }
        }} />
      )}
    </>
  );
}

const styles = StyleSheet.create({

  container: {
    flex: 1,
    backgroundColor: '#1A1A1A',
    alignItems: 'stretch',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 16
  }
});
